import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdownButton", "resources", "services"];
  toggleDropdownMobileClose(event) {
    event.target.closest(".dropdown-menu").classList.toggle("dropdown-menu");
    this.dropdownButtonTargets.forEach((element) => {
      element.classList.remove("rotate");
      element.setAttribute("aria-expanded", "false");
    });
  }
  toggleState(event) {
    this.dropdownButtonTargets.forEach((element) => {
      if (element === event.target.closest(".application-nav__link-button")) {
        element.classList.toggle("rotate");
        const expanded = element.getAttribute("aria-expanded");
        const toggledState = expanded === "true" ? "false" : "true";
        element.setAttribute("aria-expanded", toggledState);
      } else {
        element.classList.remove("rotate");
        element.setAttribute("aria-expanded", "false");
      }
    });
  }
  toggleDropdown(event) {
    if (
      event.target.closest(".application-nav__link-button").id === "services"
    ) {
      this.servicesTarget.classList.toggle("dropdown-menu");
      this.servicesTarget.focus();
      this.resourcesTarget.classList.remove("dropdown-menu");
    }
    if (
      event.target.closest(".application-nav__link-button").id === "resources"
    ) {
      this.resourcesTarget.classList.toggle("dropdown-menu");
      this.resourcesTarget.focus();
      this.servicesTarget.classList.remove("dropdown-menu");
    }
  }
}
