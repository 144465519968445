import { Controller } from 'stimulus';

export default class extends Controller {
  toggleMobileMenu(event) {
    event.preventDefault();

    this.element.classList.toggle('mobile-menu');
    if(this.element.classList.contains('mobile-menu') ){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  openSearch(event) {
    event.preventDefault();
    document.querySelector('.st-search-show-outputs').click();
  }
}
