import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["filter", "job", "none"]

  connect() {
  }

  markSelected(event) {
    var filter = event.target
    filter.parentNode.classList.toggle("selected", filter.checked)
  }

  filter() {
    var visibleJobs = []
    if (this.filterTargets.some((filter) => filter.checked)) {
      this.jobTargets.forEach((job) => {
        this.filterTargets.forEach((filter) => {
          if (filter.checked) {
            if (job.dataset[filter.dataset.type] == filter.dataset.value) {
              visibleJobs.push(job)
            }
          }
        })
      })
    } else {
      visibleJobs = this.jobTargets
    }

    this.jobTargets.forEach((job) => {
      job.classList.add("job--hidden")
    })
    visibleJobs.forEach((job) => {
      job.classList.remove("job--hidden")
    })
    this.noneTargets.forEach((none) => {
      if (!none.parentNode.querySelector("ul li:not(.job--hidden)")) {
        none.classList.remove("job--hidden")
      } else {
        none.classList.add("job--hidden")
      }
    })
  }
}
