import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const iframe = this.element;

    // listen for message and set iframe height
    window.addEventListener('message', (event) => {
      iframe.style.height = `${event.data.height}px`;
    });

    // listen for window resize events and trigger new message from iframe
    window.addEventListener('resize', () => {
      iframe.contentWindow.postMessage('resize', '*');
    });
  }
}
