import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['twitchButton', 'twitchButtonMobile', 'iconButton']
  mobile_breakpoint = 1120;

  connect() {
    const twitchScript = document.createElement('script')
    twitchScript.async = true
    twitchScript.defer = true
    document.body.appendChild(twitchScript)
    twitchScript.addEventListener('load', () => {
      setTimeout(() => {
        this.initializeTwitchPlayer()
      }, 100)
    })
    twitchScript.src = 'https://player.twitch.tv/js/embed/v1.js'
  }

  initializeTwitchPlayer() {
    this.player = new Twitch.Player('twitch-player', {
      channel: 'thoughtbot',
      width: '100%',
      height: '100%',
      autoplay: false,
    })

    window.addEventListener('resize', this.handleOnline.bind(this))
    this.player.addEventListener(Twitch.Player.READY, this.initiate.bind(this));
  }

  initiate() {
    this.addPlayerEventListeners(this.player);
  }

  addPlayerEventListeners(player) {
    player.addEventListener(Twitch.Player.ONLINE, () => {
      this.isChannelLive = true;
      this.handleOnline();
    });
    player.addEventListener(Twitch.Player.OFFLINE, () => {
      this.isChannelLive = false;
      this.handleOffline();
    });
  }

  handleOnline() {
    if (!this.isChannelLive) return;

    if (window.innerWidth < this.mobile_breakpoint) {
      this.twitchButtonMobileTarget.style.display = 'block';
      this.twitchButtonTarget.style.display = 'none';
      this.iconButtonTarget.classList.add('live');
    } else {
      this.twitchButtonTarget.style.display = 'block';
      this.twitchButtonMobileTarget.style.display = 'none';
      this.iconButtonTarget.classList.remove('live');
    }
  }

  handleOffline() {
    this.twitchButtonTarget.style.display = 'none';
    this.twitchButtonMobileTarget.style.display = 'none';
  }
}
