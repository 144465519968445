import { Controller } from 'stimulus';

export default class extends Controller {
  toggleState(event) {
    event.preventDefault();

    const expanded = this.element.getAttribute('aria-expanded');
    const toggledState = (expanded === 'true') ? 'false' : 'true';
    this.element.setAttribute('aria-expanded', toggledState);
  }
}
