import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["filterAttribute", "filterIndustry", "case", "contact", "showMore", "none"]
  static values = {
    selectedIndustry: String,
    selectedAttribute: String,
    showMore: Boolean
  }

  connect() {
    this.filter();
  }

  markSelectedIndustry(event) {
    this.selectedIndustryValue = event.target.value === "Show All" ? "" : event.target.value
    this.showMoreValue = false;
  }

  markSelectedAttribute(event) {
    this.selectedAttributeValue = event.target.value === "Show All" ? "" : event.target.value
    this.showMoreValue = false;
  }

  showMoreCaseStudies() {
    this.showMoreValue = true;
  }

  filter() {
    var visibleCases = []

    if (this.selectedIndustryValue && this.selectedAttributeValue) {
      this.caseTargets.forEach((case_study) => {
        if (case_study.dataset.industry === this.selectedIndustryValue &&
          case_study.dataset.projectAttributes.includes(this.selectedAttributeValue)) {
          visibleCases.push(case_study);
        }
      })
    } else if (this.selectedIndustryValue) {
      this.caseTargets.forEach((case_study) => {
        if (case_study.dataset.industry === this.selectedIndustryValue) {
          visibleCases.push(case_study);
        }
      })
    } else if (this.selectedAttributeValue) {
      this.caseTargets.forEach((case_study) => {
        if (case_study.dataset.projectAttributes.includes(this.selectedAttributeValue)) {
          visibleCases.push(case_study);
        }
      })
    } else {
      visibleCases = this.caseTargets
    }

    this.caseTargets.forEach((case_study) => {
      case_study.classList.add("case--hidden")
    })

    if (visibleCases.length > 0) {
      this.noneTargets.forEach(none => none.classList.add("case--hidden"))
    } else {
      this.noneTargets.forEach(none => none.classList.remove("case--hidden"))
    }

    const firstCases = visibleCases.slice(0, 5);
    visibleCases.splice(0, 5);

    firstCases.forEach((case_study) => {
      case_study.classList.remove("case--hidden")
    })

    if (visibleCases.length > 0 && !this.showMoreValue) {
      this.showMoreTargets.forEach(showMore => showMore.classList.remove("case--hidden"))
    } else {
      this.showMoreTargets.forEach(showMore => showMore.classList.add("case--hidden"))
    }

    if (this.showMoreValue) {
      visibleCases.forEach((case_study) => {
        case_study.classList.remove("case--hidden")
      })
    }
  }
}
