import {annotate} from 'rough-notation';

window.onload = function() {
  const annotatableText = document.querySelector('.text-color-accent');
  if(annotatableText) {
    const annotations = annotate(annotatableText, {
      type: 'highlight',
      color: 'var(--color-text-highlight)',
      multiline: true,
    });
    annotations.show();
  }
};
